.avatar, .folder {
  width: 24px;
  height: 24px;
}

.avatar {
  border-radius: 50%;
  margin-right: 8px;
}

.name {
  display: flex;
  align-items: center;
}

.chips {
  display: flex;
  justify-content: space-between;
}

.chip {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  mix-blend-mode: normal;
  padding: 4px 12px;
  margin: 12px 0;
  background: #E5E5E5;
  border-radius: 20px;
  width: fit-content;
  max-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.more_chip {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  mix-blend-mode: normal;
  padding: 4px 12px;
  margin: 12px 0;
  background: #E5E5E5;
  border-radius: 20px;
  width: fit-content;
  display: block;
  white-space: nowrap;
  text-overflow: clip;
  padding-right: 20px;
}

.chip:not(:first-child) {
  margin-left: 8px;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.toolbar {
  display: flex;
  align-items: center;
}