.root, .button {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
	box-sizing:border-box
}

.button {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
  padding: 9px;
  margin: 16px;
  height: 40px;
}

.icon {
  width: 20px;
  height: 20px;
  text-align: center;
}

.text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #262626;
  margin: 1.5px 0;
}

.button:hover {
  cursor: pointer;
  border: 1px solid #5F6368;
}

.button:active {
  border: 1px solid #5F6368;
}

.button:disabled {
  opacity: 0.6;
  border: 1px solid #E5E5E5;
}