.custom_menu_item_popover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 24px;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.custom_menu_item_popover:hover {
  cursor: pointer;
  background: #F5F5F5;
}

.custom_menu_item_popover__text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #262626;
}

.disabled {
  opacity: .5;
}

