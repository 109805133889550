.root, .row_item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.root {
  width: 600px;
}

.root header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.back_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.back_icon:hover {
  background-color: #F5F5F5;
  border-radius: 50%;
  cursor: pointer;
}

.root header h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #363636;
  margin-left: 16px;
}

.row_item {
  width: 100%;
}

.row_item:not(:first-child) {
  margin-top: 24px;
}

.row_item__label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #5F6368;
  display: flex;
  white-space: normal;
}

.row_item__group, .buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.row_item__group_input {
  width: 162px;
}

.buttons {
  margin-top: 44px;
}

.generate_button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #5692C7;
  margin-top: 8px;
}

.generate_button:hover {
  cursor: pointer;
}

.root h4 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #363636;
  margin: 0;
}

.root h4:not(:first-child) {
  margin-top: 44px;
}