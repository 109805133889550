.icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.user_field {
  display: flex;
  align-items: center;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.empty span {
  margin-bottom: 24px;
}

.empty button {
  background: none;
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #262626;
  padding: 11px 16px;
  border-radius: 6px;
}

.empty button:hover {
  cursor: pointer;
  background-color: #EEEEEE;
}