/*body .rs-picker-menu {*/
/*  border-radius: 3px;*/
/*  width: 308px;*/
/*  margin-top: 5px;*/
/*  background: #FFFFFF;*/
/*  border: 1px solid #EFEFF3;*/
/*  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);*/
/*}*/

/*body .rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {*/
/*  width: 285px;*/
/*}*/

/*body .rs-calendar-table-row {*/
/*  margin: 4px 0;*/
/*}*/

/*body .rs-calendar-table-cell-in-range::before {*/
/*  background-color: #E8E8ED;*/
/*  margin-top: 0;*/
/*  height: 32px;*/
/*}*/

/*body .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {*/
/*  border: none;*/
/*  font-family: Inter, sans-serif;*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 15px;*/
/*  line-height: 22px;*/
/*  color: #4D69FF;*/
/*}*/

/*body .rs-calendar-table-cell-content:hover, body .rs-calendar-month-dropdown-cell-content:hover {*/
/*  border: 2px solid #4D69FF;*/
/*  box-sizing: border-box;*/
/*  border-radius: 4px;*/
/*  padding: 3px;*/
/*  background: none;*/
/*}*/

/*body .rs-picker-daterange-header {*/
/*  display: none;*/
/*}*/

/*body .rs-calendar-table-cell-selected .rs-calendar-table-cell-content, body .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {*/
/*  background-color: #495BBF;*/
/*  border-color: #495BBF;*/
/*  box-sizing: border-box;*/
/*  border-radius: 4px;*/
/*  color: #fff;*/
/*}*/

/*body .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover, body .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {*/
/*  background-color: #495BBF;*/
/*  border-color: #495BBF;*/
/*}*/

/*body .rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {*/
/*  max-width: 300px;*/
/*  margin-top: 0;*/
/*}*/

/*body .rs-picker-toolbar-right-btn-ok {*/
/*  background-color: #495BBF;*/
/*  border-radius: 4px;*/
/*}*/

/*body .rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover, body .rs-picker-toolbar-right-btn-ok:focus, .rs-picker-toolbar-right-btn-ok.rs-btn-focus, body .rs-picker-toolbar-right-btn-ok:active, .rs-picker-toolbar-right-btn-ok.rs-btn-active, .rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok {*/
/*  background-color: #3B4BA8;*/
/*}*/

/*body .rs-picker-default:not(.rs-picker-disabled) .rs-btn,*/
/*body .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle {*/
/*  border: none;*/
/*  border-radius: 3px;*/
/*}*/

/*body .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,*/
/*body .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {*/
/*  border: none;*/
/*  border-radius: 3px;*/
/*}*/

/*body .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,*/
/*body .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,*/
/*body .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,*/
/*body .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus {*/
/*  border: 1px solid #4D69FF;*/
/*  border-radius: 3px;*/
/*}*/

/*body .rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled {*/
/*  background-color: #495BBF;*/
/*}*/
/*body .rs-calendar-month-dropdown-year-active, body .rs-calendar-show-month-dropdown .rs-calendar-header-title-date {*/
/*  color: #495BBF;*/
/*}*/

/*body .rs-picker-date.rs-picker-default .rs-picker-toggle-caret, body .rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret, body .rs-picker-date.rs-picker-default .rs-picker-toggle-clean, body .rs-picker-daterange.rs-picker-default .rs-picker-toggle-clean {*/
/*  top: 11px;*/
/*  background-repeat: no-repeat;*/
/*  width: 17px;*/
/*  height: 17px;*/
/*}*/

/*body .rs-picker-daterange .rs-picker-toggle-caret::before {*/
/*  content: none !important;*/
/*}*/

.date_root {
  position: relative;
}

.date_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  z-index: 10;
  top: 9px;
  left: 6px;
}

.date_input {
  border: none;
  max-width: 85px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #273270;
  margin: 0 6px;
}

.date_input:focus-visible, .date_input:active {
  outline: none;
}

.date_root .rs-picker-default .rs-picker-toggle, .date_root .rs-picker-default .rs-picker-toggle-active {
  border: none;
  background: none !important;
  box-shadow: none;
}

.date_root .rs-picker-default .rs-picker-toggle:focus-visible {
  outline: none;
}

.date_root .rs-picker-default .rs-picker-toggle-active .rs-picker-toggle-textbox {
  display: none;
}

.date_root .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .date_root .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
}

@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 1000px) {

  body .rs-picker-toggle-wrapper {
    display: block;
  }

}
