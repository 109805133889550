.custom_input {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.custom_input .MuiOutlinedInput-notchedOutline {
  border: none;
}

.custom_input .MuiOutlinedInput-input {
  height: 40px;
  padding: 11.5px 12px;
  background: #FFF;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #262626;
}

.custom_input.error_state .MuiOutlinedInput-input,
.custom_input.error_state .MuiOutlinedInput-input:active,
.custom_input.error_state .MuiOutlinedInput-input:focus-visible,
.custom_input.error_state .MuiOutlinedInput-input:hover {
  border: 1px solid #FF0D33;
}

.custom_input.success_state .MuiOutlinedInput-input,
.custom_input.success_state .MuiOutlinedInput-input:active,
.custom_input.success_state .MuiOutlinedInput-input:focus-visible,
.custom_input.success_state .MuiOutlinedInput-input:hover {
  border: 1px solid #3DC561;
}

.custom_input .MuiOutlinedInput-input::placeholder {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #C8C8C8;
}

.custom_input .MuiOutlinedInput-input:hover {
  border: 1px solid #5F6368;
}

.custom_input .MuiOutlinedInput-input:active,
.custom_input .MuiOutlinedInput-input:focus-visible {
  border: 1px solid #262626;
}

.custom_input .MuiOutlinedInput-input:disabled {
  background: #F5F5F5;
  border: 1px solid #E5E5E5;
}

.custom_input.error_state .MuiFormHelperText-root {
  color: #FF0D33;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  margin-left: 0;
}