.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
  height: 40px;
}

.checkbox {
  margin: 8px 16px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 24px;
  height: 24px;
}

.name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
}

.selected {
  background: #EEEEEE;
}