.root, .web_cam_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #FFFFFF;
  padding: 24px;
  box-sizing: border-box;
}

.root {
  width: 440px;
}

.web_cam_container {
  width: 600px;
}

.close_button {
  position: absolute;
  top: 24px;
  right: 24px;
}

.close_button:hover {
  cursor: pointer;
}

.header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #262626;
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
  margin-top: 24px;
  gap: 10px
}

.buttons_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 24px 0;
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
}

.action_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  padding: 16px;
}

.action_button:hover {
  border: 1px solid #5F6368;
  box-shadow: 0 3px 7px 0 #00000024;
  cursor: pointer;
}

.action_button:hover .icon svg {
  fill: #5F6368;
}

.text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
  white-space: initial;
}

.icon svg {
  fill: #9A9EA5;
}

.stream {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  position: relative;
}

.stream img {
  position: absolute;
  z-index: 1301;
}

.loading {
  width: 480px;
  height: 640px;
  position: absolute;
  z-index: 1301;
}