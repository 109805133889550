.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.006em;
  color: #262626;
}

.root:hover {
  cursor: pointer;
  background: #F5F5F5;
}

.root:active {
  background: #F5F5F5;
}