.custom_password_input__icon {
  position: absolute;
  top: 16px;
  right: 12px;
}

.custom_password_input__icon:hover {
  cursor: pointer;
}

.custom_password_input__icon img {
  width: 24px;
  height: 24px;
}

.custom_input_wrapper {
  position: relative;
}