body .MuiTooltip-tooltip  {
  background-color: #26262650;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  border-radius: 6px;
  padding: 4px 8px;
  max-width: 200px;
}