.button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 11.5px 16px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
}

.text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #262626;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.button:hover {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #5F6368;
}

.button:active {
  background: #FFFFFF;
  border: 1px solid #5F6368;
}

.button:disabled {
  background: #FFFFFF;
  opacity: 0.6;
  border: 1px solid #E5E5E5;
}