.list {
	max-height: 200px;
	overflow: auto;
}

.list li {
	white-space: unset;
	overflow-wrap: break-word;
}

.list_item {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
  overflow: initial;
  display: list-item;
}

.text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
  margin-top: 16px;
  white-space: normal;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  background-color: #FFFFFF;
  width: 600px;
  border-radius: 8px;
}

.header {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: -0.017em;
	color: #262626;
	margin: 0;
}

.title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #262626;
}

.close_button {
  position: absolute;
  top: 24px;
  right: 24px;
}

.close_button:hover {
  cursor: pointer;
}

.buttons, .list {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.buttons_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttons button:not(:last-child) {
  margin-right: 24px;
}