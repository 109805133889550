.button {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  height: 40px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #262626;
}

.button:hover {
  cursor: pointer;
  background: #EEEEEE;
  border-radius: 6px;
}