.wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 50px);
  margin-top: 5px;
}

.root, .row_item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.root {
  width: 600px;
  height: 100%;
}

.root header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.back_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.back_icon:hover {
  background-color: #F5F5F5;
  border-radius: 50%;
  cursor: pointer;
}

.root header h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #363636;
  margin-left: 16px;
}

.row_item {
  width: 100%;
}

.row_item:not(:first-child) {
  margin-top: 24px;
}

.row_item__label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #5F6368;
  display: flex;
}

.row_item__group, .buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.row_item__group_input {
  width: 162px;
}

.buttons {
  margin-top: 44px;
}

.generate_button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #5692C7;
  margin-top: 8px;
}

.generate_button:hover {
  cursor: pointer;
}

.block_photo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
  margin: 24px 0;
}

.block_photo__label, .block_photo__description, .block_photo__description_error {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.block_photo__description_error {
  color: #FF0D33;
  margin-top: 8px;
}

.block_photo__label {
  color: #5F6368;
}

.block_photo__description {
  color: #999999;
  margin-top: 8px;
}

.block_photo__buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}

.block_photo__buttons__avatar {
  width: 40px;
  height: 40px;
  border: 1px solid #E5E5E5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
}

.block_photo__buttons__avatar .icon {
  width: 24px;
  height: 24px;
}

.block_photo__buttons__avatar .photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.block_photo__buttons__load {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
  margin-right: 16px;
}

.block_photo__buttons__input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.block_photo__buttons__load:hover {
  cursor: pointer;
  border-color: #5F6368;
}

h4 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #363636;
}

.root .header_comment {
	font-family: 'Inter',	sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: -0.006em;
	color: #5F6368;
}