.custom_date_picker {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.custom_date_picker .MuiFormControl-root {
  width: 100%;
}

.custom_date_picker .MuiIconButton-root {
  padding: 8px;
}

.custom_date_picker .MuiIconButton-root:hover {
  background-color: #fff;
}

.custom_date_picker_icon {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.custom_date_picker_icon:hover path {
  fill: #000;
}

.custom_date_picker .MuiOutlinedInput-notchedOutline {
  border: 1px solid #E5E7EB;
}

.custom_date_picker .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #B1B5BB;;
}

.custom_date_picker .MuiOutlinedInput-input {
  padding: 12px;
}

.custom_date_picker .MuiInputBase-input, .MuiPickersDay-root  {
  font-family: Fira Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .5px;
  color: #000;
}

.custom_date_picker .MuiInputBase-input::placeholder {
  color: #B1B5BB;
}

.custom_date_picker .MuiFormControl-root:focus-visible, .custom_date_picker .MuiOutlinedInput-root:focus-visible {
  outline: none;
}

.custom_date_picker .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #000;
}

.MuiPickersDay-root {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  margin: 3px 4px;
}

.MuiPickersDay-root:hover {
  background-color: #F3F4F6;
}

.MuiPickersDay-root.Mui-selected, .MuiPickersDay-root:focus.Mui-selected {
  background-color: #6839CF;
  border-radius: 2px;
}

.MuiPickersDay-root.Mui-selected:hover {
  background-color: #5F30C5;
}

.MuiPickersDay-today.MuiPickersDay-root:not(.Mui-selected) {
  border: none;
  color: #6839CF;
}

.MuiCalendarPicker-root .css-1n2mv2k .MuiTypography-root {
  color: #7B7E86;
  width: 32px;
  height: 32px;
  margin: 0 4px;
}

.MuiCalendarPicker-root .MuiIconButton-root {
  padding: 8px 9.5px;
}

.PrivatePickersSlideTransition-root {
  min-height: 248px;
}

.MuiCalendarPicker-viewTransitionContainer {
  border-top: 1px solid #E5E7EB;
  padding-top: 16px;
}

.MuiCalendarPicker-root, .css-epd502 {
  width: 312px;
}

.css-1dozdou {
  margin: 0;
  padding: 13px 12px 9px 16px;
}

.css-1ozefeb .MuiPaper-root {
  border: 1px solid #E5E7EB;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.MuiMonthPicker-root {
  margin: 0;
  padding: 0 8px 16px;
  width: auto;
  justify-content: space-between;
  align-content: center;
}

.MuiMonthPicker-root .MuiTypography-root.Mui-selected,
.MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-selected,
.MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-selected:focus {
  background: #6839CF;
  border-radius: 3px;
  color: #FFFFFF;
}

.MuiMonthPicker-root .MuiTypography-root.Mui-selected:hover,
.MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-selected:hover {
  background-color: #5F30C5;
}

.MuiMonthPicker-root .MuiTypography-root, .MuiYearPicker-root .PrivatePickersYear-yearButton {
  width: 68px;
  height: 68px;
  font-family: Fira Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  flex: none;
  margin: 8px 0;
}

.MuiMonthPicker-root .MuiTypography-root:focus,
.MuiMonthPicker-root .MuiTypography-root:hover,
.MuiYearPicker-root .PrivatePickersYear-yearButton:focus,
.MuiYearPicker-root .PrivatePickersYear-yearButton:hover {
  border-radius: 3px;
  background-color: #F3F4F6;
}