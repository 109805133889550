.custom_select {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.custom_select .MuiOutlinedInput-notchedOutline {
  border: none;
}

.custom_select .MuiOutlinedInput-input {
  height: 40px;
  padding: 11.5px 12px;
  background: #FFF;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing:-0.006em;;
  color: #262626;
  text-align: start;
}

.custom_select .MuiFormControl-root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-right: 40px;
}

.custom_select .MuiInputBase-root {
  margin-top: 0;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.custom_select__item_list {
  padding: 15px 24px;
}

.custom_select .MuiSelect-icon path {
  fill: #9A9EA5;
}

.custom_select.error_state .MuiOutlinedInput-input,
.custom_select.error_state .MuiOutlinedInput-input:hover,
.custom_select.error_state .MuiOutlinedInput-input:active,
.custom_select.error_state .MuiOutlinedInput-input:focus-visible {
  border: 1px solid #FF0D33;
}

.custom_select.success_state .MuiOutlinedInput-input,
.custom_select.success_state .MuiOutlinedInput-input:hover,
.custom_select.success_state .MuiOutlinedInput-input:active,
.custom_select.success_state .MuiOutlinedInput-input:focus-visible {
  border: 1px solid #3DC561;
}

body .MuiMenuItem-root.Mui-selected {
  background: #EEEEEE;
}