.root {
  width: 800px;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
  padding: 24px;
  box-sizing: border-box;
  margin-top: 24px;
}

.root, .row_direction {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  width: 64px;
  height: 64px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar img {
  width: 100%;
  height: 100%;
}

.name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #262626;
  margin-left: 16px;
  max-width: 490px;
  white-space: initial;
  word-wrap: break-word;
}

.settings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
}

.settings:hover {
  cursor: pointer;
  background: #EEEEEE;
}

.settings_icon {
  width: 24px;
  height: 24px;
}

.settings_text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #262626;
  margin-left: 6px;
}

.actions {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}