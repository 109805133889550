.wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 50px);
  margin-top: 5px;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 600px;
}

.root header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.back_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.back_icon:hover {
  background-color: #F5F5F5;
  border-radius: 50%;
  cursor: pointer;
}

.root header h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #363636;
  margin-left: 16px;
}

.buttons {
  margin-top: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.buttons button:first-child {
  margin-right: 24px;
}

.row_item {
  width: 100%;
}

.row_item:not(:first-child) {
  margin-top: 24px;
}

.row_item__label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #5F6368;
}