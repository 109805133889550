
body .MuiCard-root {
  border: 1px solid #EEE;
  border-radius: 6px;
  box-shadow: none;
  overflow: initial;
}

.MuiCard-root:not(:first-child) {
  margin-bottom: 24px;
}

.MuiCardContent-root {
  padding: 24px;
}

.App .layout, .App .RaLayout-content {
  background-color: #fff;
  width: calc(100vw - 370px);
}

.App .RaLayout-content {
  overflow: auto;
  height: calc(100vh - 57px);
}

.css-1ic96rz-RaTabbedShowLayout-root {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

body .App .RaLayout-content .RaList-main,
body .MuiCard-root,
.App .layout {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.App .RaLayout-content .RaList-main .RaDatagrid-tableWrapper {
  overflow: auto;
}

.App .layout, .App > div  {
  overflow: hidden;
}

.App h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.014em;
  color: #363636;
  margin: 20px 0;
}

.RaLayout-appFrame > header {
  background-color: #FFF;
  box-shadow: none;
  border-bottom: 1px solid #E5E5E5;
}

.RaLayout-appFrame .MuiSvgIcon-root path {
  fill: #363636;
}

body .MuiTableRow-root {
  height: 48px;
}

body .MuiTableCell-sizeSmall {
  padding-top: 0;
  padding-bottom: 0;
}

body .MuiTableCell-sizeSmall:last-child {
  padding-left: 24px;
}

.RaSidebar-fixed .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
  display: flex;
  justify-content: flex-start;
  padding: 6px 16px 6px 24px;
}

body .layout .skip-nav-button {
  display: none;
}

body .RaAppBar-menuButton, body .RaAppBar-toolbar .MuiIconButton-sizeLarge {
  padding: 12px;
}

.css-1pvqrpk {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
	display: table;
	background-position: center;
	background-size: contain;
	list-style: none;
	overflow: auto;
	width: 100%;
}