.avatar, .folder {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.avatar {
  border-radius: 50%;
}

.name {
  display: flex;
  align-items: center;
}

.chips {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
}

.filters {
  display: flex;
  align-items: center;
}

.chip {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  mix-blend-mode: normal;
  padding: 4px 12px;
  margin: 12px 0;
  background: #E5E5E5;
  border-radius: 20px;
  width: fit-content;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.more_chip {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  mix-blend-mode: normal;
  padding: 4px 12px;
  margin: 12px 0;
  background: #E5E5E5;
  border-radius: 20px;
  width: fit-content;
  display: block;
  white-space: nowrap;
  text-overflow: clip;
  padding-right: 20px;
}

.chip:not(:first-child) {
  margin-left: 8px;
}