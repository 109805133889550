.root {
  margin-right: 12px;
}

.icon {
  width: 24px;
  height: 24px;
}

.icon:hover {
  cursor: pointer;
}