.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  background-color: #FFFFFF;
  width: 600px;
  border-radius: 8px;
}

.title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #262626;
}

.close_button {
  position: absolute;
  top: 24px;
  right: 24px;
}

.close_button:hover {
  cursor: pointer;
}

.table, .buttons, .table_header, .table_body, .limits, .input_date, .buttons_wrapper {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.buttons_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttons button:first-child {
  margin-right: 24px;
}

.table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
  margin-top: 16px;
}

.table_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid #E5E5E5;
  padding: 0 16px;
}

.table_header span {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #5F6368;
}

.table_body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 350px;
  overflow: auto;
}

.not_found {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.009em;
  color: #999999;
  margin: 25px 16px;
}

.limits {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;
}

.input_date {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.input_date span {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #5F6368;
}

.roles_wrapper {
  max-height: 360px;
  overflow: auto;
}

.list li, .text {
	white-space: unset;
	overflow-wrap: break-word;
}

.list_item {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
  overflow: initial;
  display: list-item;
}

.text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
  margin-top: 16px;
}