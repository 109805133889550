textarea {
  resize: none;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
	
}

.custom_textarea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
	width: -moz-available;
		/* For Mozzila */
	width: -webkit-fill-available;
		/* For Chrome */
	width: stretch;
	/* Unprefixed */
  margin-top: 8px;
}

.custom_textarea__length {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  margin-top: 8px;
}

.custom_textarea textarea {
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 11.5px 12px;
}

.custom_textarea textarea::placeholder {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #CCCCCC;
}

.custom_textarea textarea:hover {
  border: 1px solid #5F6368;
}

.custom_textarea textarea:active,
.custom_textarea textarea:focus-visible {
  border: 1px solid #262626;
  outline: none;
}