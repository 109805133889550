.icon {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 8px;
}

.name {
  display: flex;
  align-items: center;
}

.access_button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #5692C7;
  padding: 10px;
  /*margin-right: 50px;*/
  /*margin-left: -150px;*/
  white-space: nowrap;
	text-align: -moz-right;
}

.access_button:hover {
  cursor: pointer;
}

.menu_button {
	text-align: right;
	text-align: -moz-right;
	text-align: -webkit-right;
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}