.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
  position: relative;
}

h1.header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #363636;
  margin: 0;
  position: relative;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  padding: 11.5px 0;
  margin: 0;
  max-width: 100%;
}

.item_name {
  min-width: 250px;
  color: #5F6368;
}

.item_value {
  color: #262626;
  white-space: initial;
  word-wrap: break-word;
}

.edit_icon {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_icon:hover {
  cursor: pointer;
  background-color: #F5F5F5;
  border-radius: 50%;
}

.edit_icon:active {
  background-color: #C8C8C8;
}

.status_pending, .status_approved, .status_canceled {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  white-space: nowrap;
}

.status_pending, .status_approved, .status_canceled {
  display: flex;
  align-items: center;
  color: #262626;
}

.status_pending div, .status_approved div, .status_canceled div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 16px;
}

.status_pending div {
  background-color: #F89F33;
}

.status_approved div {
  background-color: #3DC561;
}

.status_canceled div {
  background-color: #FF0D33;
}

.icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.user_field {
  display: flex;
  align-items: center;
}

.confirm_buttons {
  position: absolute;
  top: -8px;
  right: 0;
  display: flex;
  justify-content: right;
  align-items: center;
}

.button_approval, .button_cancel, .button_change {
  display: flex;
  align-items: center;
  padding: 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
}

.button_approval:hover, .button_cancel:hover {
  cursor: pointer;
  background: #EEEEEE;
  border-radius: 6px;
}

.button_approval:active, .button_cancel:active {
  background: #C8C8C8;
  border-radius: 6px;
}

.button_approval {
  color: #5692C7;
}

.button_cancel {
  color: #262626;
}

.button_icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
