.button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #363636;
  background: #F5F5F5;
  border-radius: 6px;
  padding: 11px 16px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  width: min-content;
  border: none;
}

.button:hover {
  cursor: pointer;
  background: #EEEEEE;
}

.button:active {
  background: #C8C8C8;
}

.button:disabled {
  background: #F5F5F5;
  opacity: 0.6;
}