.line {
  height: 1px;
  background-color: #E5E5E5;
  margin: 16px 0;
}

.line, .menu_item {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

#custom-treeview .dx-treeview-item {
  padding: 0;
  color: #262626;
}

#custom-treeview .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
  background-color: #EEEEEE;
  color: #262626;
}

.menu_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu_item__count, .approvals_icon__count {
  background: #FF0D33;
  border-radius: 100px;
  height: 16px;
  min-width: 16px;
  padding: 0 4px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
}

.approvals_icon {
  display: flex;
  position: relative;
}

.approvals_icon__count {
  position: absolute;
  top: -5px;
  left: 15px;
  z-index: 100;
}

.menu_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.versions {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
  padding: 16px;
}