.button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 16px;
  background: #FF0D33;
  border-radius: 6px;
  border: none;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.button:hover {
  background: #FF0D33;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.button:active {
  background: #FF0D33;
}

.button:focus-visible {
  background: #FF0D33;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
}

.button:disabled {
  background: #E5E5E5;
}