.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.icon {
  min-width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F9F9F9;
  border-radius: 50%;
  margin-right: 16px;
}

.icon img {
  width: 24px;
  height: 24px;
}

.icon_camera {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  opacity: 0;
  background-color: #CCC;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon:hover .icon_camera {
  opacity: 1;
}

.input_photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.name {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.name div {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #262626;
  max-width: 400px;
}

span.status_active {
  background: rgba(76, 212, 155, 0.15);
  border-radius: 30px;
  font-size: 12px;
  line-height: 16px;
  color: #3DC561;
  margin-left: 24px;
  padding: 4px 8px;
}

span.status_blocked {
  background: rgba(255, 13, 51, 0.15);
  border-radius: 30px;
  font-size: 12px;
  line-height: 16px;
  color: #FF0D33;
  margin-left: 24px;
  padding: 4px 8px;
}

.role {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  color: #999999;
  margin-top: 4px;
}

.button {
  position: absolute;
  top: -8px;
  right: -8px;
}

