.root, .input {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  position: relative;
}

.input:hover {
  border: 1px solid #5F6368;
}

.input:active {
  border: 1px solid #262626;
}

.button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #5692C7;
}

.button:hover {
  cursor: pointer;
}

.chip {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  mix-blend-mode: normal;
  background: #E5E5E5;
  border-radius: 20px;
  padding: 4px 12px;
  margin: 8px 17px;
}