.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #FFFFFF;
  width: 440px;
  padding: 24px;
  box-sizing: border-box;
}

.close_button {
  position: absolute;
  top: 24px;
  right: 24px;
}

.close_button:hover {
  cursor: pointer;
}

.header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #262626;
  margin: 0;
}

.text, .role_name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
  margin-top: 16px;
  white-space: pre;
}

.text {
  white-space: normal;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}

.buttons, .list, .text, .role_name, .wrapper {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.buttons button:not(:last-child) {
  margin-right: 24px;
}