.icon {
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.user_field {
  display: flex;
  align-items: center;
}

.type, .status_pending, .status_approved, .status_canceled {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  white-space: nowrap;
}

.type {
  color: #5692C7;
}

.status_pending, .status_approved, .status_canceled {
  display: flex;
  align-items: center;
  color: #262626;
}

.status_pending div, .status_approved div, .status_canceled div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 16px;
}

.status_pending div {
  background-color: #F89F33;
}

.status_approved div {
  background-color: #3DC561;
}

.status_canceled div {
  background-color: #FF0D33;
}

.button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #262626;
  background: none;
  border: none;
}

.button:hover {
  cursor: pointer;
}

.toolbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}