.root {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top, .middle, .bottom {
  padding: 8px 0;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.middle, .bottom {
  border-top: 1px solid #E8EAED;
}