.icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-top: 6px;
}

.filters {
  display: flex;
  align-items: center;
}