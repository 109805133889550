.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 800px;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
  padding: 20px 24px;
  box-sizing: border-box;
  margin-top: 24px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.title {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #363636;
  margin-bottom: 20px;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
  padding: 8px 0;
}

.row_prop {
  width: 250px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #5F6368;
}

.row_value {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
  white-space: initial;
  word-wrap: break-word;
}

.link {
  color: #5692C7;
}

.link:hover {
  cursor: pointer;
}