.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.profile {
  max-width: 752px;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 50px);
  margin-top: 5px;
}