.root {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
}

.logo {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

.name {
  font-family: 'Glober', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 31px;
  color: #363636;
  margin: 0;
}