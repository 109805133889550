.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

h1.header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #363636;
  margin: 0;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.006em;
  padding: 11.5px 0;
  margin: 0;
}

.item_name {
  min-width: 250px;
  color: #5F6368;
}

.item_value {
  color: #262626;
  white-space: normal;
}