.root, .input {
	width: -moz-available;
	/* For Mozzila */
	width: -webkit-fill-available;
	/* For Chrome */
	width: stretch;
	/* Unprefixed */
}

.root {
  margin-top: 8px;
}

.input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  position: relative;
}

.input:hover {
  border: 1px solid #5F6368;
}

.input:active {
  border: 1px solid #262626;
}

.button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  text-transform: uppercase;
  color: #5692C7;
}

.button:hover {
  cursor: pointer;
}

.chips {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  min-width: 440px;
  margin: 0px 10px;
}

.chip {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  mix-blend-mode: normal;
  padding: 4px 12px;
  margin: 12px 0;
  background: #E5E5E5;
  border-radius: 20px;
  width: fit-content;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 340px;
}

.more_chip {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  mix-blend-mode: normal;
  padding: 4px 12px;
  margin: 12px 0;
  background: #E5E5E5;
  border-radius: 20px;
  width: fit-content;
  display: block;
  white-space: nowrap;
  text-overflow: clip;
  padding-right: 20px;
}
