.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #262626;
}

.header {
  margin: 12px 24px;
}

.item {
  margin: 8px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label {
  margin-left: 16px;
}