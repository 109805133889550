.root {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root img {
  width: 24px;
  height: 24px;
}

.root:hover {
  cursor: pointer;
  background: #F5F5F5;
  border-radius: 20px;
}

.root:active {
  background: #C8C8C8;
  border-radius: 20px;
}