.breadcrumbs, .breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.breadcrumbs {
  margin: 20px 0;
}

.breadcrumb {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #5F6368;
  user-select: none;
  max-width: 400px;
}

.breadcrumb:last-child {
  color: #363636;
}

.breadcrumb:not(:last-child):hover {
  cursor: pointer;
}

.breadcrumb:last-child {
  pointer-events: none;
}

.breadcrumb img {
  margin: 0 8px;
}