.radio_select .horizontal.MuiFormGroup-root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.radio_select .vertical.MuiFormGroup-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.radio_select .MuiFormControlLabel-root {
  margin: 0 26px 0 0;
}

.radio_select .MuiFormControlLabel-root .MuiFormControlLabel-label {
  margin-left: 12px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.35px;
  color: #000000;
}

.radio_select .MuiFormControlLabel-root .MuiSvgIcon-root {
  fill: #B1B5BB;
}

.radio_select .MuiFormControlLabel-root .Mui-checked .MuiSvgIcon-root {
  fill: #000;
}